import React, { Component } from 'react';
import { Button, Grid, Header, Menu, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import Slider from 'react-slick';

import withAuth from '../helpers/withAuth';
import * as api from '../../lib/api';
import io from '../../lib/io';
import Avatar from './ChatWorld/ChatField/Avatar';

const SliderArrow = styled.div`
  &:before {
    color: #000 !important;
  }
`;

const SliderItem = ({ children, onClick, isActive }) => {
  return (
    <Segment
      children={children}
      onClick={onClick}
      color={isActive ? 'blue' : null}
      style={{ transform: 'scale(.9)' }}
    />
  );
}

class AppearancePage extends Component {
  state = {
    isLoading: false,
    activeImageType: 'hair',
    currentAvatar: this.props.authUser.avatar.current
  }

  handleMenuItemClick = (e, { name }) => {
    this.setState({ activeImageType: name });
  }

  handleImageClick = image => {
    this.setState({
      currentAvatar: {
        ...this.state.currentAvatar,
        [this.state.activeImageType]: image
      }
    });
  }

  handleSaveClick = async () => {
    this.setState({ isLoading: true });

    await api.patch('/user/avatar', this.state.currentAvatar);
    io.emit('refreshUser');

    this.setState({ isLoading: false });
  }

  render() {
    const authUser = this.props.authUser;
    const { currentAvatar, activeImageType } = this.state;

    const avatarImages = {
      bodyImage: currentAvatar.body && currentAvatar.body.imageFilePath,
      shirtImage: currentAvatar.shirt && currentAvatar.shirt.imageFilePath,
      faceImage: currentAvatar.face && currentAvatar.face.imageFilePath,
      hairImage: currentAvatar.hair && currentAvatar.hair.imageFilePath,
      otherImage: currentAvatar.other && currentAvatar.other.imageFilePath
    };

    return (
      <React.Fragment>
        <Header as='h1' dividing>
          Appearance
          <Header.Subheader>Customize the look of your avatar</Header.Subheader>
        </Header>
        <Grid columns='equal' stretched>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Menu color='blue' vertical fluid>
                <Menu.Item
                  name='hair'
                  active={activeImageType === 'hair'}
                  onClick={this.handleMenuItemClick}
                />
                <Menu.Item
                  name='face'
                  active={activeImageType === 'face'}
                  onClick={this.handleMenuItemClick}
                />
                <Menu.Item
                  name='shirt'
                  active={activeImageType === 'shirt'}
                  onClick={this.handleMenuItemClick}
                />
                <Menu.Item
                  name='other'
                  active={activeImageType === 'other'}
                  onClick={this.handleMenuItemClick}
                />
              </Menu>
            </Grid.Column>
            <Grid.Column>
              <Segment style={{ display: 'flex', alignItems: 'center' }} raised stacked>
                <Avatar username={authUser.username} {...avatarImages} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ margin: '2rem 2rem 3rem' }}>
          <Slider
            dots={true}
            infinite={false}
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            prevArrow={<SliderArrow />}
            nextArrow={<SliderArrow />}
          >
            <SliderItem isActive={!currentAvatar[activeImageType]} onClick={() => this.handleImageClick(null)}>
              <Avatar />
            </SliderItem>
            {authUser.avatar.owned[activeImageType].map(image => (
              <SliderItem
                key={image._id}
                isActive={currentAvatar[activeImageType] && currentAvatar[activeImageType]._id === image._id}
                onClick={() => this.handleImageClick(image)}
              >
                <Avatar {...avatarImages} {...{[activeImageType + 'Image']: image.imageFilePath}} />
              </SliderItem>
            ))}
          </Slider>
        </div>
        <Button
          content='Save'
          loading={this.state.isLoading}
          onClick={this.handleSaveClick}
          primary
          fluid
        />
      </React.Fragment>
    );
  }
}

export default withAuth(AppearancePage);
