import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';

class NotFoundPage extends Component {
  render() {
    return <Header as='h1'>Page not found</Header>;
  }
}

export default NotFoundPage;
