import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Icon, Label, Menu, Modal, Segment, Table } from 'semantic-ui-react';
import _ from 'lodash';

import withAuth from '../helpers/withAuth';
import withLoader from '../helpers/withLoader';
import * as api from '../../lib/api';
import io from '../../lib/io';

class ProfilePage extends Component {
  state = {
    user: null,
    isFriend: null
  }

  componentDidMount() {
    this.fetchUser(this.props.match.params.userId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.userId !== prevProps.match.params.userId) {
      this.fetchUser(this.props.match.params.userId);
    }
  }

  fetchUser = async userId => {
    this.props.setIsLoading(true);

    try {
      const user = await api.get(`/users/${userId}`);

      this.setState({
        user: user
      });

      if (this.props.authUser._id !== user._id) {
        let isFriend = false;

        this.props.authUser.friends.some(friend => {
          if (user._id === friend._id) isFriend = true;
          return isFriend;
        });

        this.setState({
          isFriend: isFriend
        });
      } else {
        this.setState({
          isFriend: null
        });
      }
    } catch (e) {
      this.setState({
        user: null
      });
    }

    this.props.setIsLoading(false);
  }

  toggleFriend = async () => {
    this.props.setIsLoading(true);

    await api.patch(`/user/friends/${this.state.user._id}`);

    this.setState(prevState => ({
      isFriend: !prevState.isFriend
    }));

    this.props.setIsLoading(false);
  }

  goToPlayer = async () => {
    io.emit('goToPlayer', this.state.user._id);
  }

  render() {
    if (this.props.isLoading) return null;

    if (!this.state.user) {
      return <Header as='h1'>Profile not found</Header>;
    }

    return (
      <React.Fragment>
        <Segment clearing>
          <Header as='h1'>
            <Icon
              name='circle'
              color={this.state.user.isOnline ? 'green' : 'red'}
              style={{ fontSize: '.5em' }}
            />
            <Header.Content>
              {this.state.user.username}
              <Modal trigger={<Label as='a' color='blue' circular>Level {this.state.user.level}</Label>} size='mini' dimmer='inverted'>
                <Header content='Level guide' icon='clock outline' />
                <Modal.Content scrolling>
                  <p>Here are the requirements to reach specific levels.</p>
                  <Table columns={2} celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Hours online</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.range(1, 31).map(level => (
                        <Table.Row key={level}>
                          <Table.Cell>
                            {level}
                            {level <= this.state.user.level &&
                              <Icon name='checkmark' color='green' style={{ marginLeft: '.5rem' }} />
                            }
                          </Table.Cell>
                          <Table.Cell>{Math.pow(level - 1, 2)}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Modal.Content>
              </Modal>
              {this.state.user.role !== 'User' &&
                <Label circular>{this.state.user.role}</Label>
              }
            </Header.Content>
            <Header.Subheader>Profile</Header.Subheader>
          </Header>
          {this.state.isFriend !== null ? (
            <React.Fragment>
              <Button
                content={!this.state.isFriend ? 'Add as friend' : 'Remove friend'}
                color={!this.state.isFriend ? 'green' : 'red'}
                icon={!this.state.isFriend ? 'add user' : 'remove user'}
                onClick={this.toggleFriend}
                compact
              />
              {this.state.user.isOnline &&
                <Button
                  content='Go to room'
                  color='blue'
                  icon='building outline'
                  onClick={this.goToPlayer}
                  compact
                />
              }
            </React.Fragment>
          ) : (
            <Button
              as={Link}
              to='/chat/profile/settings'
              content='Settings'
              color='grey'
              icon='settings'
              compact
            />
          )}
        </Segment>
        <Menu color='blue' secondary pointing>
          <Menu.Item as='a' active>Info</Menu.Item>
        </Menu>
        <Table columns={2} celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>
                  <Header.Content>
                    Name
                    <Header.Subheader>{this.state.user.name || '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as='h4'>
                  <Header.Content>
                    Age
                    <Header.Subheader>{this.state.user.age || '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>
                  <Header.Content>
                    Gender
                    <Header.Subheader>{this.state.user.gender || '-'}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as='h4'>
                  <Header.Content>
                    Hours online
                    <Header.Subheader>
                      {`${Math.floor(this.state.user.minutesOnline / 60)} hours ${this.state.user.minutesOnline % 60} minutes`}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header content={`About ${this.state.user.username}`} icon='info circle' sub />
        {this.state.user.aboutText || <i>This user has no profile text.</i>}
      </React.Fragment>
    );
  }
}

export default withAuth(withLoader(ProfilePage));
