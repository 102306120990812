import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export default function withLoader(WrappedComponent) {
  return class extends Component {
    state = {
      isLoading: false
    }

    setIsLoading = isLoading => {
      this.setState({
        isLoading: isLoading
      });
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            isLoading={this.state.isLoading}
            setIsLoading={this.setIsLoading}
          />
          <Dimmer active={this.state.isLoading} inverted>
            <Loader content='Loading...' />
          </Dimmer>
        </React.Fragment>
      );
    }
  };
}
