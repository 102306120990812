import React, { Component } from 'react';
import { Comment, Header, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import withAuth from '../helpers/withAuth';

class ChatMessagesPage extends Component {
  handleWhisperClick = username => {
    const chatMessageInput = this.props.chatMessageInput.current;

    chatMessageInput.inputRef.value = `/whisper ${username} `;
    chatMessageInput.focus();
  }

  render() {
    return (
      <React.Fragment>
        <Header as='h1' dividing>Messages</Header>
        {this.props.chatMessages.length
          ? <Comment.Group style={{ maxWidth: 'none' }} minimal>
              {this.props.chatMessages.map((message, i) =>
                <Comment key={i}>
                  <Comment.Content>
                    <Comment.Author as={Link} to={`/chat/profile/${message.user._id}`}>
                      {message.user.username}
                    </Comment.Author>
                    <Comment.Metadata>
                      {message.user.role !== 'User' && <span>[{message.user.role}]</span>}
                      <span>
                        <Moment fromNow>{message.dateTime}</Moment>
                      </span>
                    </Comment.Metadata>
                    <Comment.Text>
                      {message.whisperTo &&
                        <Label style={{ marginRight: '.25rem' }} size='small' circular>
                          {message.whisperTo === this.props.authUser.username
                            ? `${message.whisperTo} whispers to you`
                            : `You whisper to ${message.whisperTo}`
                          }
                        </Label>
                      }
                      {message.text}
                    </Comment.Text>
                    {message.user.username !== this.props.authUser.username &&
                      <Comment.Actions>
                        <Comment.Action onClick={() => this.handleWhisperClick(message.user.username)}>Whisper</Comment.Action>
                      </Comment.Actions>
                    }
                  </Comment.Content>
                </Comment>
              )}
            </Comment.Group>
          : <p>There are no messages.</p>
        }
      </React.Fragment>
    );
  }
}

export default withAuth(ChatMessagesPage);
