import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Header } from 'semantic-ui-react';
import _ from 'lodash';

import withAuth from '../helpers/withAuth';
import withStatusMessage from '../helpers/withStatusMessage';
import * as api from '../../lib/api';

const ageSelectOptions = _.map(_.range(100), age => ({ text: age, value: age}));
const genderSelectOptions = [
  { text: 'None', value: 'None' },
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' }
];

class ProfileSettingsPage extends Component {
  state = {
    isLoading: false,
    name: this.props.authUser.name,
    age: this.props.authUser.age,
    gender: this.props.authUser.gender,
    aboutText: this.props.authUser.aboutText
  }

  handleSubmit = async () => {
    this.props.clearStatusMessage();

    this.setState({
      isLoading: true
    });

    try {
      await api.patch('/user', {
        name: this.state.name,
        age: this.state.age,
        gender: this.state.gender,
        aboutText: this.state.aboutText
      });

      this.props.setStatusMessage(false, 'Settings have been saved.');
    } catch (e) {
      this.props.setStatusMessage(true, e.message, e.errors);
    }

    this.setState({
      isLoading: false
    });
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header as='h1' dividing>
          Settings
          <Header.Subheader>
            <Link to={`/chat/profile/${this.props.authUser._id}`}>Back to profile</Link>
          </Header.Subheader>
        </Header>
        <Form loading={this.state.isLoading} onSubmit={this.handleSubmit}>
          {this.props.statusMessage}
          <Form.Group widths='equal'>
            <Form.Input
              label='Name'
              placeholder='Name'
              name='name'
              value={this.state.name}
              onChange={this.handleChange}
              fluid
            />
            <Form.Select
              label='Age'
              placeholder='Age'
              name='age'
              value={this.state.age}
              options={ageSelectOptions}
              onChange={this.handleChange}
              fluid
            />
            <Form.Select
              label='Gender'
              placeholder='Gender'
              name='gender'
              value={this.state.gender}
              options={genderSelectOptions}
              onChange={this.handleChange}
              fluid
            />
          </Form.Group>
          <Form.TextArea
            label='About text'
            placeholder='Write something about yourself...'
            name='aboutText'
            value={this.state.aboutText}
            onChange={this.handleChange}
          />
          <Form.Button primary>Save</Form.Button>
        </Form>
      </React.Fragment>
    );
  }
}

export default withAuth(withStatusMessage(ProfileSettingsPage));
