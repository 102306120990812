import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import _ from 'lodash';

class FriendListDropdown extends Component {
  render() {
    const friendsOnlineCount = this.props.friends.filter(friend => friend.isOnline).length;

    return (
      <Dropdown text={`${friendsOnlineCount} friends online`} icon='users' className='icon' button labeled scrolling basic>
        <Dropdown.Menu>
          {this.props.friends.length
            ? _.orderBy(this.props.friends, ['isOnline', 'username'], 'desc').map(friend => (
                <Dropdown.Item
                  key={friend._id}
                  as={Link}
                  to={`/chat/profile/${friend._id}`}
                  text={friend.username}
                  icon={<Icon name='circle' color={friend.isOnline ? 'green' : 'red'} />}
                />
              ))
            : <Dropdown.Header>You have no friends :-(</Dropdown.Header>
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default FriendListDropdown;
