import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import io from '../../../lib/io';
import ContextMenu from './ContextMenu';
import { ChatField, ClickableChatField } from './ChatField';

const StyledChatWorld = styled.div`
  width: 720px;
  height: 500px;
  overflow: hidden;
  position: relative;
  user-select: none;
  box-sizing: border-box;
`;

const RoomName = styled.div`
  line-height: 1em;
  padding: .5rem;
  top: 0;
  right: 0;
  z-index: 2;
  position: absolute;
  opacity: .5;
  font-size: .75em;
  font-weight: bold;
  color: #fff;
  border-radius: 0 0 0 .5rem;
  background-color: #000;
`;

const ClickLayer = styled.div`
  z-index: 2;
  position: absolute;
`;

const RoomLayer = styled.div`
  z-index: 1;
  position: absolute;
  image-rendering: pixelated;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('${process.env.PUBLIC_URL}${props => props.imageFilePath}');
`;

const ContextMenuWithRef = React.forwardRef((props, ref) => (
  <ContextMenu {...props} forwardedRef={ref} />
));

class ChatWorld extends Component {
  state = {
    overlayText: 'Connecting...',
    roomState: null,
    chatMessages: {},
    contextMenuPlayer: null
  }

  rootNode = React.createRef();
  contextMenuNode = React.createRef();
  chatMessageTimeoutIds = {}

  componentDidMount() {
    io.on('connect', () => this.setOverlayText(null));
    io.on('reconnecting', () => this.setOverlayText('Reconnecting...'));
    io.on('disconnect', () => this.setOverlayText('Disconnected'));
    io.on('error', error => this.setOverlayText(`Error: ${error}`));

    io.on('roomState', roomState => this.setState({ roomState }));
    io.on('chatMessage', message => this.handleChatMessage(message));

    io.connect();
  }

  componentWillUnmount() {
    io.removeAllListeners();
    io.disconnect();
    Object.values(this.chatMessageTimeoutIds).forEach(id => clearTimeout(id));
  }

  setOverlayText = text => {
    this.setState({ overlayText: text });
  }

  handleChatMessage = message => {
    clearTimeout(this.chatMessageTimeoutIds[message.user._id]);

    let chatMessages = {...this.state.chatMessages};
    chatMessages[message.user._id] = message;
    this.setState({ chatMessages });

    this.chatMessageTimeoutIds[message.user._id] = setTimeout(() => {
      let chatMessages = {...this.state.chatMessages};
      delete chatMessages[message.user._id];
      this.setState({ chatMessages });
    }, 10000);
  }

  handleMovePlayer = fieldIndex => {
    this.closeContextMenu();
    io.emit('movePlayer', fieldIndex);
  }

  closeContextMenu = () => {
    if (this.state.contextMenuPlayer) {
      this.setState({ contextMenuPlayer: null });
    }
  }

  handleContextMenu = (e, fieldIndex) => {
    e.preventDefault();
    this.closeContextMenu();

    const player = _.find(this.state.roomState.players, { fieldIndex });
    if (!player) return;

    const rootNodeRect = this.rootNode.current.getBoundingClientRect();
    const mousePosition = {
      x: e.clientX - rootNodeRect.left,
      y: e.clientY - rootNodeRect.top
    };

    this.contextMenuNode.current.style.left = mousePosition.x + 5 + 'px';
    this.contextMenuNode.current.style.top = mousePosition.y + 'px';

    this.setState({ contextMenuPlayer: player });
  }

  render() {
    return (
      <StyledChatWorld ref={this.rootNode} onContextMenu={e => e.preventDefault()}>
        <Dimmer active={!!this.state.overlayText}>
          <Loader content={this.state.overlayText} />
        </Dimmer>
        {this.state.roomState &&
          <React.Fragment>
            <ContextMenuWithRef
              ref={this.contextMenuNode}
              player={this.state.contextMenuPlayer}
              chatMessageInput={this.props.chatMessageInput}
              closeContextMenu={this.closeContextMenu}
            />
            <RoomName>{this.state.roomState.room.name}</RoomName>
            <ClickLayer>
              {_.times(60, fieldIndex => (
                <ClickableChatField
                  key={fieldIndex}
                  fieldIndex={fieldIndex}
                  onClick={this.handleMovePlayer}
                  onContextMenu={this.handleContextMenu}
                />
              ))}
            </ClickLayer>
            <RoomLayer imageFilePath={this.state.roomState.room.imageFilePath}>
              {_.times(60, fieldIndex => {
                const player = _.find(this.state.roomState.players, { fieldIndex });
                const chatMessage = player && this.state.chatMessages[player.user._id];

                return (
                  <ChatField
                    key={fieldIndex}
                    player={player}
                    chatMessage={chatMessage}
                  />
                );
              })}
            </RoomLayer>
          </React.Fragment>
        }
      </StyledChatWorld>
    );
  }
}

export default ChatWorld;
