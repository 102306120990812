import React, { Component } from 'react';
import { Button, Card, Header, Label, Menu, Popup } from 'semantic-ui-react';
import _ from 'lodash';

import withAuth from '../helpers/withAuth';
import withLoader from '../helpers/withLoader';
import * as api from '../../lib/api';
import Avatar from './ChatWorld/ChatField/Avatar';

class ShopPage extends Component {
  state = {
    authUser: this.props.authUser,
    activeImageType: 'hair',
    avatarImages: []
  }

  async componentDidMount() {
    this.props.setIsLoading(true);

    const avatarImages = await api.get('/avatarimages');

    this.setState({
      avatarImages: _.groupBy(avatarImages, 'type')
    });

    this.props.setIsLoading(false);
  }

  handleMenuItemClick = (e, { name }) => {
    this.setState({ activeImageType: name });
  }

  buyImage = async imageId => {
    await api.post(`/user/avatar/${imageId}`);

    const authUser = await api.get('/user');
    this.setState({ authUser });
  }

  render() {
    if (this.props.isLoading) return null;

    const authUser = this.state.authUser;
    const activeImageType = this.state.activeImageType;

    return (
      <React.Fragment>
        <Header as='h1' dividing>
          Shop
          <Header.Subheader>Buy clothes for your avatar</Header.Subheader>
        </Header>
        <Menu widths={4} color='blue' pointing>
          <Menu.Item
            name='hair'
            active={activeImageType === 'hair'}
            onClick={this.handleMenuItemClick}
          />
          <Menu.Item
            name='face'
            active={activeImageType === 'face'}
            onClick={this.handleMenuItemClick}
          />
          <Menu.Item
            name='shirt'
            active={activeImageType === 'shirt'}
            onClick={this.handleMenuItemClick}
          />
          <Menu.Item
            name='other'
            active={activeImageType === 'other'}
            onClick={this.handleMenuItemClick}
          />
        </Menu>
        {!_.isEmpty(this.state.avatarImages[activeImageType])
          ? <Card.Group itemsPerRow={2} style={{ margin: 0 }}>
              {this.state.avatarImages[activeImageType].map(image => {
                let cantBuyMessage;

                if (_.find(authUser.avatar.owned[image.type], { _id: image._id })) {
                  cantBuyMessage = 'You already own this.';
                } else if (authUser.level < image.minLevel) {
                  cantBuyMessage = 'You don\'t meet the level requirement.';
                } else if (authUser.credits < image.price) {
                  cantBuyMessage = 'You don\'t have enough credits.';
                }

                const buyButton = (
                  <div>
                    <Button
                      content='Buy'
                      color='blue'
                      icon='shop'
                      labelPosition='left'
                      disabled={!!cantBuyMessage}
                      onClick={() => this.buyImage(image._id)}
                      fluid
                    />
                  </div>
                );

                return (
                  <Card key={image._id} link raised>
                    <Label attached='top'>{image.price} credits</Label>
                    <Card.Content textAlign='center'>
                      <Avatar
                        {...{[activeImageType + 'Image']: image.imageFilePath}}
                        bodyImage={authUser.avatar.current.body.imageFilePath}
                      />
                      <Label style={{ margin: '.5rem 0 0' }} size='tiny' basic>
                        Level {image.minLevel}
                      </Label>
                    </Card.Content>
                    <Card.Content extra>
                      <Popup
                        trigger={buyButton}
                        content={cantBuyMessage}
                        disabled={!cantBuyMessage}
                        size='mini'
                        position='top center'
                        inverted
                      />
                    </Card.Content>
                  </Card>
                );
              })}
            </Card.Group>
          : <p>There is nothing in this category.</p>
        }
      </React.Fragment>
    );
  }
}

export default withAuth(withLoader(ShopPage));
