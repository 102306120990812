import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Popup } from 'semantic-ui-react';

import withAuth from '../../helpers/withAuth';
import * as api from '../../../lib/api';
import FriendListDropdown from './FriendListDropdown';
import SearchUsersInput from './SearchUsersInput';

class TopBar extends Component {
  state = {
    authUser: this.props.authUser
  }

  componentDidMount() {
    this.fetchAuthUserIntervalId = setInterval(() => {
      this.fetchAuthUser();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchAuthUserIntervalId);
  }

  fetchAuthUser = async () => {
    const authUser = await api.get('/user');
    this.setState({ authUser });
  }

  render() {
    const { authUser } = this.state;
    const nextLevelMinutes = Math.pow(authUser.level, 2) * 60 - authUser.minutesOnline;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button as='div' labelPosition='right' style={{ marginRight: '1rem' }}>
          <Button as={Link} to={`/chat/profile/${authUser._id}`} basic>
            <Icon name='user' />
            {authUser.username}
          </Button>
          <Popup
            trigger={<Label as='a' pointing='left' basic>{authUser.level}</Label>}
            content={`Next level in ${Math.floor(nextLevelMinutes / 60)}h ${nextLevelMinutes % 60}m`}
            size='mini'
            position='bottom center'
            inverted
          />
        </Button>
        <Button
          as={Link}
          to='/chat/shop'
          content={`${authUser.credits} credits`}
          icon='money'
          labelPosition='left'
          style={{ marginRight: '1rem' }}
          basic
        />
        <FriendListDropdown friends={authUser.friends} />
        <SearchUsersInput />
      </div>
    );
  }
}

export default withAuth(TopBar);
