import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Search } from 'semantic-ui-react';
import _ from 'lodash';

import * as api from '../../../lib/api';

const resultRenderer = ({ username, isOnline }) => {
  return (
    <React.Fragment>
      <Icon
        name='circle'
        color={isOnline ? 'green' : 'red'}
        style={{ marginRight: '.75rem' }}
      />
      {username}
    </React.Fragment>
  );
};

class SearchUsersInput extends Component {
  state = {
    isLoading: false,
    value: '',
    results: []
  }

  componentWillUnmount() {
    this.fetchResults.cancel();
  }

  fetchResults = _.debounce(async () => {
    if (!this.state.value.length) return this.clearSearchState();
    const users = await api.get(`/users?search=${this.state.value}`);

    this.setState({
      isLoading: false,
      results: users
    });
  }, 500)

  clearSearchState = () => {
    this.setState({
      isLoading: false,
      value: '',
      results: []
    });
  }

  handleSearchChange = (e, { value }) => {
    if (!value.length) return this.clearSearchState();
    this.setState({ isLoading: true, value }, this.fetchResults);
  }

  handleResultSelect = (e, { result }) => {
    this.props.history.push(`/chat/profile/${result._id}`);
    this.clearSearchState();
  }

  render() {
    return (
      <Search
        placeholder='Search users...'
        loading={this.state.isLoading}
        value={this.state.value}
        results={this.state.results}
        resultRenderer={resultRenderer}
        noResultsMessage='No users found.'
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        style={{ marginLeft: 'auto' }}
        fluid
      />
    );
  }
}

export default withRouter(SearchUsersInput);
