import React, { Component } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { Grid, Menu, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import withAuth from '../helpers/withAuth';
import io from '../../lib/io';

import TopBar from './TopBar';
import ChatWorld from './ChatWorld';
import ChatMessageInput from './ChatMessageInput';

import ChatRoomsPage from './ChatRoomsPage';
import ChatMessagesPage from './ChatMessagesPage';
import ProfilePage from './ProfilePage';
import ProfileSettingsPage from './ProfileSettingsPage';
import AppearancePage from './AppearancePage';
import ShopPage from './ShopPage';
import NotFoundPage from '../NotFoundPage';

const StyledGridColumn = styled(Grid.Column)`
  width: 720px !important;
  flex-grow: 0 !important;
  box-sizing: content-box;
`;

const SidebarContent = styled.div`
  height: 500px;
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
`;

const ChatMessageInputWithRef = React.forwardRef((props, ref) => (
  <ChatMessageInput {...props} forwardedRef={ref} />
));

class ChatPage extends Component {
  state = {
    chatMessages: []
  }

  chatMessageInput = React.createRef();

  componentDidMount() {
    io.on('chatMessage', this.handleChatMessage);
  }

  componentWillUnmount() {
    io.off('chatMessage', this.handleChatMessage);
  }

  handleChatMessage = message => {
    this.setState(prevState => ({
      chatMessages: [message, ...prevState.chatMessages.slice(0, 99)]
    }));
  }

  render() {
    return (
      <React.Fragment>
        <TopBar />
        <Grid columns='equal' padded='vertically' divided='vertically' centered>
          <Grid.Row>
            <StyledGridColumn>
              <ChatWorld chatMessageInput={this.chatMessageInput} />
              <ChatMessageInputWithRef ref={this.chatMessageInput} />
            </StyledGridColumn>
            <Grid.Column>
              <SidebarContent>
                <Switch>
                  <Route path='/chat(/rooms)?' component={ChatRoomsPage} exact />
                  <Route
                    path='/chat/messages'
                    render={() => <ChatMessagesPage chatMessageInput={this.chatMessageInput} chatMessages={this.state.chatMessages} />}
                    exact
                  />
                  <Route path='/chat/profile/settings' component={ProfileSettingsPage} exact />
                  <Route path='/chat/profile/:userId' component={ProfilePage} exact />
                  <Route path='/chat/appearance' component={AppearancePage} exact />
                  <Route path='/chat/shop' component={ShopPage} exact />
                  <Route component={NotFoundPage} />
                </Switch>
              </SidebarContent>
              <Menu widths={5} icon>
                <Popup
                  trigger={<Menu.Item as={NavLink} to='/chat/rooms' icon='building outline' exact />}
                  content='Rooms'
                  position='top center'
                  inverted
                />
                <Popup
                  trigger={<Menu.Item as={NavLink} to='/chat/messages' icon='comments outline' exact />}
                  content='Messages'
                  position='top center'
                  inverted
                />
                <Popup
                  trigger={<Menu.Item as={NavLink} to={`/chat/profile/${this.props.authUser._id}`} icon='user outline' exact />}
                  content='Profile'
                  position='top center'
                  inverted
                />
                <Popup
                  trigger={<Menu.Item as={NavLink} to='/chat/appearance' icon='smile outline' exact />}
                  content='Appearance'
                  position='top center'
                  inverted
                />
                <Popup
                  trigger={<Menu.Item as={NavLink} to='/chat/shop' icon='money bill alternate outline' exact />}
                  content='Shop'
                  position='top center'
                  inverted
                />
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withAuth(ChatPage);
