import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';
import styled from 'styled-components';

import StyledChatField from './StyledChatField';
import Avatar from './Avatar';

const ChatMessageBubble = styled.div`
  width: 100px;
  min-height: 60px;
  padding: .5em;
  z-index: 1;
  position: absolute;
  opacity: .9;
  overflow-wrap: break-word;
  border: 1px solid #000;
  border-radius: .5rem;
  background-color: ${props => props.isWhisper ? '#c8e1f3' : '#fff'};

  ${props => props.isTop ? 'bottom: 30px;' : 'top: 10px;'}
  ${props => props.isRight ? 'left: 60px;' : 'right: 60px;'}

  &:after {
    width: 10px;
    height: 10px;
    content: '';
    position: absolute;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    background-color: ${props => props.isWhisper ? '#c8e1f3' : '#fff'};

    ${props => props.isTop ? 'bottom: 10px;' : 'top: 40px;'}
    ${props => props.isRight ? `
      left: -6px;
      transform: rotate(135deg);
    ` : `
      right: -6px;
      transform: rotate(-45deg);
    `}
  }
`;

class ChatField extends Component {
  render() {
    if (!this.props.player) return <StyledChatField />;

    const currentAvatar = this.props.player.user.avatar.current;
    const avatarImages = {
      bodyImage: currentAvatar.body && currentAvatar.body.imageFilePath,
      shirtImage: currentAvatar.shirt && currentAvatar.shirt.imageFilePath,
      faceImage: currentAvatar.face && currentAvatar.face.imageFilePath,
      hairImage: currentAvatar.hair && currentAvatar.hair.imageFilePath,
      otherImage: currentAvatar.other && currentAvatar.other.imageFilePath
    };

    return (
      <StyledChatField>
        <Transition.Group animation='scale' duration={250}>
          {this.props.chatMessage &&
            <ChatMessageBubble
              isTop={this.props.player.fieldIndex >= 24}
              isRight={this.props.player.fieldIndex % 12 < 6}
              isWhisper={this.props.chatMessage.whisperTo}
              children={this.props.chatMessage.text}
            />
          }
        </Transition.Group>
        <Avatar
          {...avatarImages}
          username={this.props.player.user.username}
        />
      </StyledChatField>
    );
  }
}

export default ChatField;
