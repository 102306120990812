import React, { Component } from 'react';
import { Form, Header, Input } from 'semantic-ui-react';

import withStatusMessage from './helpers/withStatusMessage';
import * as api from '../lib/api';

class RegisterPage extends Component {
  state = {
    isLoading: false
  }

  usernameInput = React.createRef();
  passwordInput = React.createRef();
  repeatPasswordInput = React.createRef();

  handleSubmit = async () => {
    const usernameInput = this.usernameInput.current.inputRef;
    const passwordInput = this.passwordInput.current.inputRef;
    const repeatPasswordInput = this.repeatPasswordInput.current.inputRef;

    this.props.clearStatusMessage();

    if (passwordInput.value !== repeatPasswordInput.value) {
      this.props.setStatusMessage(true, 'Passwords does not match.');
      return
    }

    this.setState({
      isLoading: true
    });

    try {
      await api.post('/users', {
        username: usernameInput.value,
        password: passwordInput.value
      });

      this.props.setStatusMessage(false, 'User was successfully created.');

      usernameInput.value = '';
      passwordInput.value = '';
      repeatPasswordInput.value = '';
    } catch (e) {
      this.props.setStatusMessage(true, e.message, e.errors);
    }

    this.setState({
      isLoading: false
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header as='h1'>Register</Header>
        <Form loading={this.state.isLoading} onSubmit={this.handleSubmit} style={{ width: 350 }}>
          {this.props.statusMessage}
          <Form.Field>
            <Input icon='user' iconPosition='left' placeholder='Username' ref={this.usernameInput} required />
          </Form.Field>
          <Form.Field>
            <Input type='password' icon='lock' iconPosition='left' placeholder='Password' ref={this.passwordInput} required />
          </Form.Field>
          <Form.Field>
            <Input type='password' icon='lock' iconPosition='left' placeholder='Repeat password' ref={this.repeatPasswordInput} required />
          </Form.Field>
          <Form.Button content='Register' primary />
        </Form>
      </React.Fragment>
    );
  }
}

export default withStatusMessage(RegisterPage);
