import _ from 'lodash';

export const request = async (method, url, opts = {}) => {
  opts.method = method;
  opts.headers = opts.headers || {};

  opts.headers['Accept'] = 'application/json';
  opts.credentials = 'include';

  if (opts.body) {
    opts.headers['Content-Type'] = 'application/json';
    opts.body = JSON.stringify(opts.body);
  }

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, opts);
    if (response.status === 204) return;
    const data = await response.json();

    if (response.ok) return data;
    if (response.status === 400) throw new ValidationError(data.errors);
    throw new APIError(response.status, data.error);
  } catch (e) {
    if (e instanceof APIError) throw e;
    throw new Error('Something went wrong. Try again later.');
  }
};

export const get = async (url, opts = {}) => {
  return await request('GET', url, opts);
};

export const post = async (url, data = {}, opts = {}) => {
  return await request('POST', url, _.defaultsDeep({ body: data }, opts));
};

export const put = async (url, data = {}, opts = {}) => {
  return await request('PUT', url, _.defaultsDeep({ body: data }, opts));
};

export const patch = async (url, data = {}, opts = {}) => {
  return await request('PATCH', url, _.defaultsDeep({ body: data }, opts));
};

export class APIError extends Error {
  constructor(code, message) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
};

export class ValidationError extends APIError {
  constructor(errors) {
    super(400, 'Validation error');
    this.errors = errors;
  }
};
