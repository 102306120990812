import React, { Component } from 'react';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic-ui-react';

import { AuthContext } from '../context';
import * as api from '../lib/api';

import HomePage from './HomePage';
import ChatPage from './ChatPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import NotFoundPage from './NotFoundPage';

class App extends Component {
  state = {
    authUser: undefined
  }

  componentDidMount() {
    this.fetchAuthUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchAuthUser();
    }
  }

  fetchAuthUser = async () => {
    try {
      const user = await api.get('/user');
      this.setState({ authUser: user });
    } catch (e) {
      this.setState({ authUser: null });
    }
  }

  logoutAuthUser = async () => {
    await api.post('/logout');
    this.fetchAuthUser();
    this.props.history.push('/');
  }

  render() {
    return (
      <AuthContext.Provider value={this.state.authUser}>
        <Container style={{ margin: '1rem 0' }}>
          <Menu size='large' color='blue' stackable>
            <Menu.Item header>Avatar chat</Menu.Item>
            <Menu.Item as={NavLink} to='/' exact>Home</Menu.Item>
            {this.state.authUser &&
              <Menu.Item as={NavLink} to='/chat'>Chat</Menu.Item>
            }
            <Menu.Menu position='right'>
              {this.state.authUser ? (
                <Dropdown text={this.state.authUser.username} item>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.logoutAuthUser}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <React.Fragment>
                  <Menu.Item as={NavLink} to='/login'>Log in</Menu.Item>
                  <Menu.Item as={NavLink} to='/register'>Register</Menu.Item>
                </React.Fragment>
              )}
            </Menu.Menu>
          </Menu>
          <Switch>
            <Route path='/' component={HomePage} exact />
            <Route path='/chat' component={ChatPage} />
            <Route path='/login' component={LoginPage} exact />
            <Route path='/register' component={RegisterPage} exact />
            <Route component={NotFoundPage} />
          </Switch>
        </Container>
      </AuthContext.Provider>
    );
  }
}

export default withRouter(App);
