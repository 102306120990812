import React, { Component } from 'react';
import { Form, Header, Input } from 'semantic-ui-react';

import withStatusMessage from './helpers/withStatusMessage';
import * as api from '../lib/api';

class LoginPage extends Component {
  state = {
    isLoading: false
  }

  usernameInput = React.createRef();
  passwordInput = React.createRef();

  handleSubmit = async () => {
    this.props.clearStatusMessage();

    this.setState({
      isLoading: true
    });

    try {
      await api.post('/login', {
        username: this.usernameInput.current.inputRef.value,
        password: this.passwordInput.current.inputRef.value
      });

      this.props.history.push('/');
    } catch (e) {
      this.props.setStatusMessage(true, e.message, e.errors);

      this.setState({
        isLoading: false
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header as='h1'>Log in</Header>
        <Form loading={this.state.isLoading} onSubmit={this.handleSubmit} style={{ width: 350 }}>
          {this.props.statusMessage}
          <Form.Field>
            <Input icon='user' iconPosition='left' placeholder='Username' ref={this.usernameInput} required />
          </Form.Field>
          <Form.Field>
            <Input type='password' icon='lock' iconPosition='left' placeholder='Password' ref={this.passwordInput} required />
          </Form.Field>
          <Form.Button content='Log in' primary />
        </Form>
      </React.Fragment>
    );
  }
}

export default withStatusMessage(LoginPage);
