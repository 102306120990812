import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import withAuth from '../../helpers/withAuth';

const StyledContextMenu = styled.div`
  margin: 0;
  z-index: 3;
  position: absolute;
  display: ${props => props.hasPlayer ? 'block' : 'none'};
`;

class ContextMenu extends Component {
  handleWhisperClick = () => {
    const chatMessageInput = this.props.chatMessageInput.current;

    chatMessageInput.inputRef.value = `/whisper ${this.props.player.user.username} `;
    chatMessageInput.focus();

    this.props.closeContextMenu();
  }

  render() {
    return (
      <StyledContextMenu ref={this.props.forwardedRef} hasPlayer={this.props.player}>
        {this.props.player &&
          <Menu size='small' style={{ width: 'auto' }} vertical>
            <Menu.Item>
              <Menu.Header>{this.props.player.user.username}</Menu.Header>
              <Menu.Menu>
                <Menu.Item
                  as={Link}
                  to={`/chat/profile/${this.props.player.user._id}`}
                  content='View profile'
                  onClick={this.props.closeContextMenu}
                />
                {this.props.authUser._id !== this.props.player.user._id &&
                  <Menu.Item
                    content='Whisper'
                    onClick={this.handleWhisperClick}
                  />
                }
              </Menu.Menu>
            </Menu.Item>
          </Menu>
        }
      </StyledContextMenu>
    );
  }
}

export default withAuth(ContextMenu);
