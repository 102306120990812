import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../context';

export default function withAuth(WrappedComponent) {
  return class extends Component {
    static contextType = AuthContext;

    render() {
      if (this.context === undefined) return null;
      if (!this.context) return <Redirect to='/login' />;

      return <WrappedComponent {...this.props} authUser={this.context} />;
    }
  };
}
