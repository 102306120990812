import React, { Component } from 'react';
import StyledChatField from './StyledChatField';

class ClickableChatField extends Component {
  handleClick = () => this.props.onClick(this.props.fieldIndex);
  handleContextMenu = e => this.props.onContextMenu(e, this.props.fieldIndex);

  render() {
    return (
      <StyledChatField
        onClick={this.handleClick}
        onContextMenu={this.handleContextMenu}
      />
    );
  }
}

export default ClickableChatField;
