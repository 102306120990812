import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';

class HomePage extends Component {
  render() {
    return <Header as='h1'>Welcome</Header>;
  }
}

export default HomePage;
