import styled from 'styled-components';

const StyledChatField = styled.div`
  width: 60px;
  height: 100px;
  float: left;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, .2);
    border-radius: 5px;
  }
`;

export default StyledChatField;
