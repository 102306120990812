import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

export default function withStatusMessage(WrappedComponent) {
  return class extends Component {
    state = {
      isError: false,
      messageHeader: null,
      messageItems: null
    }

    clearMessage = () => {
      this.setState({
        isError: false,
        messageHeader: null,
        messageItems: null
      });
    }

    setMessage = (isError, header, items = null) => {
      this.setState({
        isError: isError,
        messageHeader: header,
        messageItems: items
      });
    }

    render() {
      const message = (
        <Message
          size='small'
          icon={this.state.isError ? 'warning circle' : 'check circle'}
          header={this.state.messageHeader}
          list={this.state.messageItems}
          success={!this.state.isError}
          error={this.state.isError}
          visible={!!this.state.messageHeader}
        />
      );

      return (
        <WrappedComponent
          {...this.props}
          statusMessage={message}
          clearStatusMessage={this.clearMessage}
          setStatusMessage={this.setMessage}
        />
      );
    }
  };
}
