import React, { Component } from 'react';
import styled from 'styled-components';

const StyledAvatar = styled.div`
  width: 60px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  image-rendering: pixelated;
`;

const ImageLayer = styled.img`
  position: absolute;
`;

const Username = styled.div`
  line-height: 1.5em;
  padding: 0 .25rem;
  bottom: 0;
  position: absolute;
  font-size: .75em;
  font-weight: bold;
  text-shadow: 0 0 3px #000;
  border-radius: .25rem;
  color: #fff;
  background-color: rgba(0, 0, 0, .5);
`;

class Avatar extends Component {
  render() {
    const { bodyImage, shirtImage, faceImage, hairImage, otherImage } = this.props;

    return (
      <StyledAvatar>
        {bodyImage && <ImageLayer src={`${process.env.PUBLIC_URL}${bodyImage}`} alt='' />}
        {shirtImage && <ImageLayer src={`${process.env.PUBLIC_URL}${shirtImage}`} alt='' />}
        {faceImage && <ImageLayer src={`${process.env.PUBLIC_URL}${faceImage}`} alt='' />}
        {hairImage && <ImageLayer src={`${process.env.PUBLIC_URL}${hairImage}`} alt='' />}
        {otherImage && <ImageLayer src={`${process.env.PUBLIC_URL}${otherImage}`} alt='' />}
        {this.props.username && <Username>{this.props.username}</Username>}
      </StyledAvatar>
    );
  }
}

export default Avatar;
