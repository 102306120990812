import React, { Component } from 'react';
import { Header, List } from 'semantic-ui-react';

import withAuth from '../helpers/withAuth';
import withLoader from '../helpers/withLoader';
import * as api from '../../lib/api';
import io from '../../lib/io';

class ChatRoomsPage extends Component {
  state = {
    rooms: []
  }

  async componentDidMount() {
    this.props.setIsLoading(true);

    const rooms = await api.get('/rooms');

    this.setState({
      rooms: rooms
    });

    this.props.setIsLoading(false);
  }

  changeRoom = roomId => {
    io.emit('changeRoom', roomId);
  }

  render() {
    if (this.props.isLoading) return null;

    return (
      <React.Fragment>
        <Header as='h1' dividing>Rooms</Header>
        {this.state.rooms.length
          ? <List selection size='large' verticalAlign='middle'>
              {this.state.rooms.map((room, i) =>
                <List.Item key={i} onClick={() => this.changeRoom(room._id)}>
                  <List.Icon name='angle right' />
                  <List.Content>
                    <List.Header>{room.name}</List.Header>
                  </List.Content>
                </List.Item>
              )}
            </List>
          : <p>There are no rooms.</p>
        }
      </React.Fragment>
    );
  }
}

export default withAuth(withLoader(ChatRoomsPage));
