import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import io from '../../lib/io';

class ChatMessageInput extends Component {
  handleKeyUp = ({ key, target: { value } }) => {
    if (key === 'Enter' && !!value.trim()) {
      io.emit('chatMessage', value);
      this.props.forwardedRef.current.inputRef.value = '';
    }
  }

  render() {
    return (
      <Input
        size='large'
        icon='comment outline'
        placeholder='Type message  &#x23ce;'
        maxLength={75}
        style={{ marginTop: '1rem' }}
        onKeyUp={this.handleKeyUp}
        ref={this.props.forwardedRef}
        fluid
      />
    );
  }
}

export default ChatMessageInput;
